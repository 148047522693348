import { useNavigate } from 'react-router-dom';
import { Container, Logo, MainButton } from '../../components';
import { Paths } from '../../shared/const/route-paths';
import S from './styles/auth.module.css';

export const Auth = () => {
  const navigate = useNavigate();

  return (
    <Container variant="card" className={S.container}>
      <Logo />

      <div className={S.buttons}>
        <MainButton
          htmlType="button"
          onClick={() => navigate(`/${Paths.SIGN_UP}`, { replace: true })}
        >
          {'Sign Up'}
        </MainButton>

        <MainButton
          htmlType="button"
          type="default"
          onClick={() => navigate(`/${Paths.LOG_IN}`, { replace: true })}
        >
          {'Log in'}
        </MainButton>
      </div>
    </Container>
  );
};
