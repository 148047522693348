import { Select, type SelectProps, Typography } from 'antd';
import FormItem, { type FormItemProps } from 'antd/es/form/FormItem';
import { DefaultAvatar } from '../DefaultAvatar/DefaultAvatar';
import S from './style.module.css';

interface Props {
  label?: string | React.ReactNode;
  formItemProps?: FormItemProps;
  name?: string;
  withAvatar?: boolean;
}

export const SelectLabeled: React.FC<Props & SelectProps> = ({
  label,
  formItemProps,
  name,
  options,
  withAvatar,
  ...props
}) => {
  return (
    <FormItem
      label={
        <Typography.Text className={S.label} type="secondary">
          {label}
        </Typography.Text>
      }
      {...formItemProps}
      name={name}
    >
      <Select {...props}>
        {options &&
          options.map((item) => (
            <Select.Option key={item.value}>
              {withAvatar && (
                <DefaultAvatar size={24} style={{ marginRight: 8 }} />
              )}
              {item.label}
            </Select.Option>
          ))}
      </Select>
    </FormItem>
  );
};
