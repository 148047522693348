import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { type AvatarProps } from 'antd/es/avatar/index';

export const DefaultAvatar: React.FC<AvatarProps> = ({ src, ...props }) => {
  return (
    <Avatar
      icon={!src ? <UserOutlined width="100%" rev={undefined} /> : null}
      {...props}
    />
  );
};
