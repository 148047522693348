import { Typography } from 'antd';

import { Container } from '../../components';

export const CheckYourEmail: React.FC = () => {
  return (
    <Container variant="card">
      <Typography.Title level={3}>Forgot password</Typography.Title>
      <Typography.Paragraph type="secondary">
        Link was sent successfully, please check your email to proceed
      </Typography.Paragraph>
    </Container>
  );
};
