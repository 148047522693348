import { store } from '../../stores/store';
import { userActions } from './../../stores/user/slice';
import { RegistrationRequest, UserData } from './../../types/entities';
import { ENDPOINTS } from '../../shared/const/endpoints';
import { authRequest } from '../../shared/utils/authRequest';
import type { AxiosResponse } from 'axios';

export class AuthService {
  static async register(
    data: RegistrationRequest,
  ): Promise<AxiosResponse<UserData>> {
    return authRequest({
      method: 'post',
      url: ENDPOINTS.SIGN_UP,
      data,
    });
  }
  static async getToken(
    data: Pick<RegistrationRequest, 'username' | 'password'>,
  ): Promise<
    AxiosResponse<{
      access: string;
      refresh: string;
    }>
  > {
    return authRequest({
      method: 'post',
      url: ENDPOINTS.LOG_IN,
      data,
    });
  }
  static async refreshToken(data: { refresh: string; token?: string }): Promise<
    AxiosResponse<{
      access: string;
      refresh: string;
    }>
  > {
    return authRequest({
      method: 'post',
      url: ENDPOINTS.REFRESH_TOKEN,
      data,
    });
  }
  static async checkTokenValidity(token: string | null | undefined) {
    const res = await authRequest({
      method: 'post',
      url: ENDPOINTS.VERIFY_TOKEN,
      data: { token },
    });

    if (res?.data) return true;
  }
  static logOut() {
    store.dispatch(userActions.logOut());
    localStorage.clear();
  }
}
