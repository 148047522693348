import { Navigate } from 'react-router-dom';
import { Paths } from '../../shared/const/route-paths';

interface Props {
  isAuthenticated: boolean;
  children: React.ReactElement<any, any> | null;
}

export const PrivateRoute: React.FC<Props> = ({
  isAuthenticated,
  children,
}) => {
  const token = localStorage.getItem('_auth');

  return !isAuthenticated && !token ? (
    <Navigate replace to={`/${Paths.AUTH}`} />
  ) : (
    children
  );
};
