import { Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';

import { ALL_QUESTIONS_WITH_KEYS } from '../../../../shared/const/questions';
import { MainButton } from '../../../../components/MainButton/MainButton';
import S from './style.module.css';

export const Questions = () => {
  const { topic, id } = useParams();

  const currentTopicQuestion = ALL_QUESTIONS_WITH_KEYS.find(
    ({ key }) => key === topic,
  );

  return (
    <>
      <Typography.Title level={2} style={{ margin: 0 }}>
        Other {currentTopicQuestion.key}-related questions you can ask:
      </Typography.Title>
      <div className={S.container}>
        {currentTopicQuestion.questions
          .filter((item) => item.id !== +id)
          .map(({ id, question }) => (
            <div key={id}>
              <Link to={`../../${id}`} key={id} relative="path">
                <MainButton
                  htmlType="button"
                  type="default"
                  className={S.topicButton}
                >
                  {question}
                </MainButton>
              </Link>
            </div>
          ))}
      </div>
    </>
  );
};

export default Questions;
