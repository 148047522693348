import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

export interface Alert {
  message: string;
  id: string;
  type: 'success' | 'error' | 'info' | 'warning';
}

export interface AlertState {
  alerts: Alert[];
  queue: Alert[];
}

const initialState: AlertState = {
  alerts: [],
  queue: [],
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState: initialState,
  reducers: {
    addAlert(
      state,
      action: PayloadAction<{
        message: string;
        type: 'success' | 'error' | 'info' | 'warning';
        status?: number | string;
      }>,
    ) {
      if (action.payload?.status && action.payload.status === 401) return;

      const uniqueAlertId = uniqueId('alert');
      const alertWithId = { ...action.payload, id: uniqueAlertId };

      if (state.alerts.length < 3) {
        state.alerts.push(alertWithId);
      } else {
        state.queue.push(alertWithId);
      }
    },
    deleteAlert(state, action: PayloadAction<string>) {
      state.alerts = state.alerts.filter(
        (alert) => alert.id !== action.payload,
      );
    },
    replaceAlertFromQueue(state, action: PayloadAction<Alert>) {
      state.alerts.push(action.payload);
    },
    clearAlerts(state) {
      state.alerts = [];
      state.queue = [];
    },
  },
});

export const { actions, reducer } = alertSlice;
