export function getEnv(name: string, fallback?: string): string | undefined {
  const windowEnv =
    (window as any)['_env_'] && (window as any)['_env_']?.[name];
  if (windowEnv && windowEnv !== 'null' && windowEnv !== 'undefined')
    return windowEnv;
  const processEnv = process.env[name];
  if (processEnv && processEnv !== 'null' && processEnv !== 'undefined')
    return processEnv;
  return fallback;
}
