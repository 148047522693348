import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { type AvatarProps } from 'antd/es/avatar/index';
import { useAppSelector } from '../../hooks/hooks';
import * as userSelectors from '../../stores/user/selectors';

export const UserAvatar: React.FC<AvatarProps> = ({ size, ...props }) => {
  const userData = useAppSelector(userSelectors.getUserData);

  return (
    <Avatar
      size={size ? size : 128}
      icon={<UserOutlined width="100%" rev={undefined} />}
      alt="User avatar"
      src={userData?.avatar?.url}
      {...props}
    />
  );
};
