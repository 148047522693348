import { type AxiosResponse } from 'axios';
import { chatRequest } from '../../shared/utils/chatRequest';
import { QuestionInputs, AnswerData } from '../../types/common';
import { ENDPOINTS } from './../../shared/const/endpoints';

export class ChatService {
  static async getAnswer(
    data: QuestionInputs,
  ): Promise<AxiosResponse<AnswerData>> {
    return chatRequest({
      method: 'post',
      url: ENDPOINTS.GENERATE_ANSWER,
      data,
    });
  }
}
