import { Button, type ButtonProps } from 'antd';
import S from './style.module.css';

export const MainButton: React.FC<ButtonProps> = ({
  children,
  type = 'primary',
  size = 'large',
  htmlType = 'submit',
  className,
  ...props
}) => (
  <Button
    type={type}
    size={size}
    htmlType={htmlType}
    className={`${S.mainButton} ${className || ''}`}
    {...props}
  >
    {children}
  </Button>
);
