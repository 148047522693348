import S from './style.module.css';
import { useMemo } from 'react';
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
  variant?: 'vertical' | 'space-between' | 'centered' | 'card';
}

export const Container: React.FC<Props> = ({
  className,
  children,
  variant = 'centered',
  ...props
}) => {
  const variantClassName = useMemo(() => {
    switch (variant) {
      case 'vertical':
        return S.vertical;

      case 'space-between':
        return S.between;

      case 'card':
        return S.card;

      default:
        return S.centered;
    }
  }, [variant]);

  return (
    <div className={`${variantClassName} ${className || ''}`} {...props}>
      {children}
    </div>
  );
};
