import { LogoutOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useSignOut } from 'react-auth-kit';
import { Link, useNavigate } from 'react-router-dom';
import { UserAvatar } from '../../../../components/UserAvatar/UserAvatar';
import { Paths } from '../../../../shared/const/route-paths';
import { useAppSelector } from '../../../../hooks/hooks';
import { AuthService } from '../../../../services/auth/service';
import { getUserData } from '../../../../stores/user/selectors';

import S from './style.module.css';

interface Props {
  className?: string;
  innerContainerClassName?: string;
  setOpened?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HeaderConrols: React.FC<Props> = ({
  className,
  innerContainerClassName,
  setOpened,
}) => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);

  const isTooLong = userData?.name && userData.name.length > 25;

  const handleLogOut = () => {
    signOut();
    AuthService.logOut();
    navigate(`/${Paths.LOG_IN}`, { replace: true });
    if (setOpened) setOpened(false);
  };

  return (
    <div className={`${S.container} ${className || ''}`}>
      <div
        onClick={() => {
          if (setOpened) setOpened(false);
        }}
      >
        <Link
          to={Paths.APP.PROFILE.INDEX}
          className={`${S.link} ${innerContainerClassName || ''}`}
        >
          <UserAvatar size={32} />
          <div className={`${S.userName} ${isTooLong ? S.wrap : ''}`}>
            <p className={S.userNameText}>{userData?.name || ''}</p>
          </div>
        </Link>
      </div>
      <Tooltip title="log out">
        <LogoutOutlined
          onClick={handleLogOut}
          rev={undefined}
          className={S.logout}
        />
      </Tooltip>
    </div>
  );
};
