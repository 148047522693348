import { LoginForm } from '../../features/auth';
import { Typography } from 'antd';
import { Container, MainButton } from '../../components';
import { Link } from 'react-router-dom';
import { Paths } from '../../shared/const/route-paths';
import S from './styles/log-in.module.css';

export const LogIn = () => {
  return (
    <Container variant="card" className={S.container}>
      <Typography.Title level={2} style={{ marginBottom: 0 }}>
        {'Log in'}
      </Typography.Title>

      <LoginForm />

      <Container variant="space-between" className={S.innerContainer}>
        <Typography.Paragraph style={{ marginBottom: 0 }}>
          {"Don't have an account yet?"}
        </Typography.Paragraph>
        <Link to={`/${Paths.SIGN_UP}`}>
          <MainButton htmlType="button" type="link" size="small">
            {'Sign Up'}
          </MainButton>
        </Link>
      </Container>
    </Container>
  );
};
