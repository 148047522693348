import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Container, MainButton } from '../../components';
import { Paths } from '../../shared/const/route-paths';

export const ChangePasswordSuccess = () => {
  return (
    <Container variant="card">
      <Typography.Title level={3}>
        Password updated successfully!
      </Typography.Title>
      <Link to={`/${Paths.LOG_IN}`} style={{ width: '100%' }}>
        <MainButton htmlType="button">{'Log in'}</MainButton>
      </Link>
    </Container>
  );
};
