import { AxiosError } from 'axios';
import type { FieldData } from 'rc-field-form/es/interface';

export const getFieldsErrors = (
  error: AxiosError<Record<string, string[]>>,
): FieldData[] | undefined => {
  if (!error?.response) return;
  return Object.entries(error.response.data).map(
    ([name, errors]) =>
      ({
        name,
        errors,
      } as FieldData),
  );
};
