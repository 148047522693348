/* eslint-disable no-unused-vars */
export enum INPUT_TYPES {
  TEXT = 'text',
  PASSWORD = 'password',
  EMAIL = 'email',
  LOGIN = 'login',
  PHONE = 'phone',
  TEXT_AREA = 'textarea',
  CHECKBOX = 'checkbox',
}
