import LogoImage from '../../assets/logo.svg';
import { Image, type ImageProps } from 'antd';

export const Logo: React.FC<ImageProps> = ({ ...props }) => {
  return (
    <Image
      src={props?.src ? props.src : LogoImage}
      alt="logo"
      preview={false}
      {...props}
    />
  );
};
