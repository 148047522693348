import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import type { RootState, AppDispatch } from '../stores/store';
import { useEffect } from 'react';
import { UserApi } from '../services/user/api';
import { userActions } from '../stores/user/slice';
import { userSelectors } from '../stores';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../shared/const/route-paths';
import { AuthService } from '../services/auth/service';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export async function useUserData() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(userSelectors.getIsLoggedIn);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(userActions.setLoading(true));

      UserApi.getUserData()
        .then((res) => res?.data)
        .then((res) => dispatch(userActions.setUserData(res)))
        .finally(() => dispatch(userActions.setLoading(false)));
    }
  }, [dispatch, isAuthenticated]);
}

export function useLogout() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (!event.key && !event.newValue) {
        dispatch(userActions.setIsLoggedIn(false));
        AuthService.logOut();
      }
    };

    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  }, [dispatch]);
}

export function useAuthenticateUser() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(userSelectors.getIsLoggedIn);
  const token = localStorage.getItem('_auth');
  const refreshToken = localStorage.getItem('_auth_refresh');

  useEffect(() => {
    if (!isAuthenticated && token) {
      dispatch(userActions.setLoading(true));
      AuthService.checkTokenValidity(token)
        .then((isTokenValid) => {
          if (isTokenValid) {
            dispatch(userActions.setIsLoggedIn(true));
            // navigate(Paths.APP.INDEX);
          }
        })
        .catch(() => {
          if (refreshToken) {
            AuthService.refreshToken({
              refresh: refreshToken,
            })
              .then((res) => {
                localStorage.setItem('_auth', res.data.access);
                localStorage.setItem('_auth_refresh', res.data.refresh);

                dispatch(userActions.setIsLoggedIn(true));
                // navigate(Paths.APP.INDEX);
              })
              .catch(() => {
                localStorage.removeItem('_auth');
                localStorage.removeItem('_auth_refresh');
                if (isAuthenticated) {
                  dispatch(userActions.setIsLoggedIn(false));
                  navigate(Paths.AUTH, { replace: true });
                }
              });
          } else {
            dispatch(userActions.setIsLoggedIn(false));
            navigate(Paths.AUTH, { replace: true });
          }
        })
        .finally(() => dispatch(userActions.setLoading(false)));
    }
  }, [dispatch, isAuthenticated, navigate, refreshToken, token]);
}
