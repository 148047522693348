import { Container, Logo } from '../../../../components';
import { SideBarMenu } from '../../../../components/SideBarMenu/SideBarMenu';
import { HeaderConrols } from '../HeaderControls/HeaderConrols';
import S from './style.module.css';

interface Props {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BurgerMenu: React.FC<Props> = ({ opened, setOpened }) => {
  return (
    <Container
      variant="vertical"
      className={`${S.container} ${opened ? S.active : ''}`}
    >
      <div
        className={`${S.blur} ${opened ? S.active : ''}`}
        onClick={() => setOpened(false)}
      ></div>
      <Container variant="vertical" className={S.innerContainer}>
        <Logo />
        <HeaderConrols
          className={`${S.headerControls}`}
          innerContainerClassName={S.innerHeadeControls}
          setOpened={setOpened}
        />
        <SideBarMenu
          className={`${S.sidebarMenu} ${opened ? S.inBurger : ''}`}
          setOpened={setOpened}
        />
      </Container>
    </Container>
  );
};
