import { useEffect, useState } from 'react';
import { Typography, Spin } from 'antd';
import { Link, useParams } from 'react-router-dom';

import { Container, MainButton } from '../../components';
import { Paths } from '../../shared/const/route-paths';
import { UserApi } from '../../services/user/api';

export const ActivateEmail: React.FC = () => {
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    const onActivate = async () => {
      try {
        await UserApi.activateEmail({
          uid: params.uid as string,
          token: params.token as string,
        });
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    onActivate();
  }, [params]);

  if (loading) {
    return (
      <Container variant="card">
        <Spin spinning />
      </Container>
    );
  }

  return (
    <Container variant="card">
      {error ? (
        <Typography.Paragraph type="secondary">
          Email wasn&rsquo;t activated successfully, please try again later
        </Typography.Paragraph>
      ) : (
        <Typography.Paragraph type="secondary">
          Email was activated successfully
        </Typography.Paragraph>
      )}
      <Link to={`/${Paths.LOG_IN}`} style={{ width: '100%' }}>
        <MainButton htmlType="button">{'Log in'}</MainButton>
      </Link>
    </Container>
  );
};
