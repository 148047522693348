import { useNavigate, useParams } from 'react-router-dom';
import { Paths } from '../../../shared/const/route-paths';
import { UserApi } from '../../../services/user/api';
import { ForgotPasswordForm } from '../ForgotPasswordForm/ForgotPasswordForm';
import {
  ResetPasswordFields,
  ResetPasswordConfirmFields,
} from '../../../types/entities';

interface Props {
  formMode: 'reset' | 'resetConfirm';
}

export const ForgotPasswordMode: React.FC<Props> = ({ formMode }) => {
  const navigate = useNavigate();
  const params = useParams();

  const isResetConfirm = formMode === 'resetConfirm';

  const onReset = async (values: ResetPasswordFields) => {
    await UserApi.resetPassword({
      username: values.username,
    });

    navigate(`/${Paths.CHECK_YOUR_EMAIL}`);
  };

  const onResetConfirm = async (values: ResetPasswordConfirmFields) => {
    await UserApi.resetPasswordConfirm({
      uid: params.uid as string,
      token: params.token as string,
      new_password: values.password,
    });

    navigate(`/${Paths.CHANGE_PASSWORD_SUCCESS}`);
  };

  return (
    <ForgotPasswordForm
      formMode={formMode}
      onSubmit={isResetConfirm ? onResetConfirm : onReset}
    />
  );
};
