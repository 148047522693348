import { Typography } from 'antd';
import { Container } from '../../components';
import { ForgotPasswordMode } from '../../features/auth/forgot-password-mode/ForgotPasswordMode';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';

export const ForgotPassword = () => {
  return (
    <Container variant="card">
      <Typography style={{ textAlign: 'center' }}>
        <Title level={2}>{'Forgot password'}</Title>

        <Paragraph strong type="secondary" style={{ marginBottom: 0 }}>
          {'Enter your email and we send you a link to reset your password'}
        </Paragraph>
      </Typography>

      <ForgotPasswordMode formMode="reset" />
    </Container>
  );
};
