import { Typography } from 'antd';
import { Container } from '../../components';
import { ForgotPasswordMode } from '../../features/auth/forgot-password-mode/ForgotPasswordMode';
import Title from 'antd/es/typography/Title';

export const ResetPasswordConfirm = () => {
  return (
    <Container variant="card">
      <Typography style={{ textAlign: 'center' }}>
        <Title level={2}>{'New password'}</Title>
      </Typography>

      <ForgotPasswordMode formMode="resetConfirm" />
    </Container>
  );
};
