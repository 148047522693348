import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { Container } from '../../../components';
import S from './style.module.css';
import { Alert, Typography } from 'antd';
import * as alertSelectors from '../../../stores/alert/selectors';
import { useEffect } from 'react';
import { actions as alertActions } from '../../../stores/alert/slice';

export const AlertProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const alerts = useAppSelector(alertSelectors.getAlerts);
  const alertQueue = useAppSelector(alertSelectors.getAlertQueue);

  useEffect(() => {
    if (alerts.length < 3 && alertQueue.length > 0) {
      dispatch(
        alertActions.replaceAlertFromQueue(alertQueue[alertQueue.length - 1]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts, dispatch]);

  return (
    <>
      <Container
        className={`${S.mainContainer} ${alerts.length === 0 ? S.empty : ''}`}
        variant="vertical"
      >
        {alerts.map((alert) => {
          setTimeout(() => {
            dispatch(alertActions.deleteAlert(alert.id));
          }, 5000);

          return (
            <Alert
              message={
                <Typography.Text strong>{alert.message}</Typography.Text>
              }
              key={alert.id}
              type={alert.type}
              style={{
                width: '100%',
                cursor: 'pointer',
                boxShadow: '-1px 2px 7px 0px rgba(0,0,0,0.75)',
                padding: 8,
              }}
              onClick={() => dispatch(alertActions.deleteAlert(alert.id))}
              showIcon
            />
          );
        })}
      </Container>

      {children}
    </>
  );
};
