import { WarningOutlined } from '@ant-design/icons';

import S from './style.module.css';

export const ChatInfoBlock = () => (
  <>
    <div className={S.title}>Accuracy Is Important!</div>
    <div className={S.textContainer}>
      <WarningOutlined rev="" className={S.icon} />
      <p className={S.text}>
        Building the trucking industry&apos;s most accurate and relevant
        benchmarking database is a team effort. You can help yourself and your
        peers the most by entering the most current and accurate information you
        have. Thank you for contributing to this important effort to improve
        your business and the trucking industry as a whole.
      </p>
    </div>
  </>
);

export default ChatInfoBlock;
