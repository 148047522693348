import axios, { type InternalAxiosRequestConfig } from 'axios';
import { ENDPOINTS } from '../const/endpoints';
import { BASE_URL } from '../const/envVariables';
import { AuthService } from '../../services/auth/service';

interface Config extends InternalAxiosRequestConfig<any> {
  retry?: boolean;
}

const makeRequest = axios.create({ baseURL: BASE_URL });

makeRequest.interceptors.request.use(
  (config: Config) => {
    if (!config.headers?.Authorization || config.retry) {
      const accessToken = window.localStorage.getItem('_auth');
      if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },

  (error) => Promise.reject(error),
);

makeRequest.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;
    const refreshToken = window.localStorage.getItem('_auth_refresh');
    const isCorsError =
      error.code === 'ERR_NETWORK' && error.config.url !== ENDPOINTS.LOG_IN;

    const retryRefreshToken = async (): Promise<unknown> => {
      if (!originalConfig.retry) {
        try {
          originalConfig.retry = true;
          if (!refreshToken) {
            AuthService.logOut();
            return;
          }
          const response = await AuthService.refreshToken({
            refresh: refreshToken,
          });

          window.localStorage.setItem('_auth', response.data.access);
          window.localStorage.setItem('_auth_refresh', response.data.refresh);

          return makeRequest.request(originalConfig);
        } catch (err) {
          AuthService.logOut();
          return Promise.reject(err);
        }
      }
      return Promise.reject(error);
    };
    if (isCorsError) {
      retryRefreshToken();
    }

    switch (error.response.status) {
      case 401: {
        return retryRefreshToken();
      }
      default:
        return Promise.reject(error);
    }
  },
);
export default makeRequest;
