import { Container } from '../../components';

import { UploadAvatar } from '../../features/profile/UploadAvatar/UploadAvatar';
import { RegisterFormMode } from '../../features/auth/RegisterFormMode/RegisterFormMode';

export const EditProfile = () => {
  return (
    <Container variant="vertical" style={{ alignItems: 'start' }}>
      <UploadAvatar />

      <RegisterFormMode formMode="edit" />
    </Container>
  );
};
