import { AuthProvider } from 'react-auth-kit';
import { App } from './App';

import { Provider } from 'react-redux';
import { store } from '../stores/store';

import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import refreshApi from '../services/auth/api';
import { AlertProvider } from '../shared/providers/alert-provider/AlertProvider';
import { mainTheme } from '../shared/configs/theme-config';

export const AppContainer = () => {
  return (
    <Provider store={store}>
      <ConfigProvider theme={mainTheme}>
        <AuthProvider
          authType={'localstorage'}
          authName={'_auth'}
          cookieDomain={window.location.hostname}
          cookieSecure={false}
          refresh={refreshApi}
        >
          <BrowserRouter>
            <AlertProvider>
              <App />
            </AlertProvider>
          </BrowserRouter>
        </AuthProvider>
      </ConfigProvider>
    </Provider>
  );
};
