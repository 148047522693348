import { EXPIRES_IN, EXPIRES_IN_REFRESH } from '../../shared/const/common';
import { createRefresh } from 'react-auth-kit';

import { AuthService } from './service';

const refreshApi = createRefresh({
  interval: 5,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  refreshApiCallback: async ({ authToken, refreshToken }) => {
    try {
      const response = await AuthService.refreshToken({
        refresh: refreshToken as string,
        token: authToken,
      });

      return {
        isSuccess: true,
        newAuthToken: response.data.access,
        newAuthTokenExpireIn: Number(EXPIRES_IN),
        newRefreshTokenExpiresIn: Number(EXPIRES_IN_REFRESH),
      };
    } catch (error) {
      console.error(error);
      return {
        isSuccess: false,
      };
    }
  },
});

export default refreshApi;
