import { ControlOutlined } from '@ant-design/icons';
import { Col, DatePicker, Input, Row, Typography } from 'antd';
import { Toggler } from '..';
import S from './style.module.css';
import { useWindowSize } from '../../hooks/useWindowResize';

interface Props<T> {
  filters: { label: string; filterByField: string; date?: boolean }[][];
  setfilterCritetia: React.Dispatch<React.SetStateAction<T>>;
}

export const Filters = <T,>({ filters, setfilterCritetia }: Props<T>) => {
  const [windowWidth] = useWindowSize();
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    setfilterCritetia((prev) => ({
      ...prev,
      [type]: event.target.value,
    }));
  };

  return (
    <Toggler
      noBorder
      defaultText
      containerProps={{ style: { width: '100%' } }}
      label={
        <>
          <ControlOutlined rev={undefined} style={{ color: '#898C90' }} />
          {'Filter'}
        </>
      }
      titleStyle={{ maxWidth: 80 }}
    >
      <div style={{ width: '100%' }}>
        {filters.map((item, index) => {
          return (
            <Row
              style={{ marginBottom: 20 }}
              key={`filterRow${index}`}
              justify="space-between"
              className={S.row}
            >
              {item.map((input, inputIndex) => (
                <Col
                  className={S.column}
                  span={windowWidth > 815 ? Math.floor(23 / item.length) : 24}
                  key={`filterInput${inputIndex}`}
                >
                  <Typography.Paragraph
                    type="secondary"
                    style={{ marginBottom: 5 }}
                  >
                    {input.label}
                  </Typography.Paragraph>
                  {input?.date ? (
                    <DatePicker.RangePicker
                      style={{ width: '100%' }}
                      onChange={(e) => {
                        setfilterCritetia((prev) => ({
                          ...prev,
                          [input.filterByField]: e ? `${e[0]} - ${e[1]}` : '',
                        }));
                      }}
                    />
                  ) : (
                    <Input
                      placeholder={input.label}
                      onChange={(e) => onChange(e, input.filterByField)}
                    />
                  )}
                </Col>
              ))}
            </Row>
          );
        })}
      </div>
    </Toggler>
  );
};
